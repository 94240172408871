<main class="p-10 md:px-16 mlg:px-36">
  <section
    class="bg-title-bg bg-no-repeat md:bg-[center_left_10rem] mlg:bg-[center_left_35rem] py-16 animate-fade-down">
    <h2 class="text-center text-4xl font-semibold">Privacy policy</h2>
  </section>

  <section class="animate-fade-up">
    <h3 class="font-semibold text-base">Introduction</h3>
    <p class="text-[15px] pb-6">011Telecom is dedicated to protecting the privacy of individuals whose personal data we
      hold for information processing or may collect for our business use. This includes Licensees and users of its
      Platform-as-a-Service software and virtual telephone number VNumber℠ and its VNumber℠ Mobile App. It also includes
      visitors to our general website, prospects who request information about us, in addition to our Licensees. This
      Privacy Policy describes how 011Telecom may collect (or not collect), use, and disclose personal data, and options
      regarding 011Telecom’s holding and use of various forms of personal data in the course of its services and
      business.</p>
    <p class="text-[15px] pb-6">011Telecom’s Services are not intended for use by minors. Minors are children under the
      age of eighteen (18). 011Telecom will not knowingly collect personal data from persons under the natural age of
      eighteen. If you are under the age of eighteen (18), do not use our Services and do not provide your information
      to 011Telecom.</p>

    <h3 class="font-semibold text-base">Data We Collect from General Website</h3>
    <h3 class="font-semibold text-base">Cookies and Other Technologies</h3>
    <p class="text-[15px] pb-6">The 011Telecom website, and navigation through it, may include our use of cookies, web
      beacons, pixels, or other technologies to collect data about your access, your device, and interaction or activity
      on the website. This is used to improve the customer’s experience, gather business analytics on our services,
      better navigation, provide future functionality, recognize your access, and personalize the customer experience
      from any device. This may include navigation from the landing point on our general website through to your
      Licensee account setup and use of the Platform thereafter.</p>

    <h3 class="font-semibold text-base">California User Right to Deletion, Correction or Opt-out</h3>
    <p class="text-[15px] pb-6">As a California resident and user of 011Telecom’s VNumber℠ services, the following
      apply:</p>
    <p class="text-[15px] pb-6">As a website browser of our websites, you may request Deletion, Correction, and/or
      Opt-Out of our data for our internal purposes. Please contact us at support&#64;vnumber.com for any such request
      and
      have available information such as the date of your visit(s) to our website and any other information that might
      assist us in ensuring that your information is deleted or corrected. All Opt-Outs may be processed through the
      same email address. We will contact you to coordinate the process. All information obtained from our website is
      stored in secure network platforms with reasonable and commercially current data privacy protections for the type
      of data collected. In the event of a data breach, 011Telecom will give applicable notice to the public as
      prescribed by law.</p>
    <p class="text-[15px] pb-6">As a Customer or Retail user of our websites or portals, Sensitive Information about you
      or your business is essential to the performance of our services. So far as you are a customer, use the mobile
      application, or have an open account, this information may not be deleted without frustrating the operation of our
      services. Opt-out of the use of this Sensitive Data is not available but may be requested as a part of closing
      your account. Correction of Sensitive Data may be performed through your account by you directly. Retail users,
      who are businesses and provide information that does not have the potential to identify a California household or
      resident individually, are subject to exclusions under the CCPA.</p>
    <p class="text-[15px] pb-6">011Telecom does not discriminate on any basis in its compliance with the CCPA or with
      regard to any request from a California resident as related to a California household.</p>

    <h3 class="font-semibold text-base">SEO and Web Marketing Service Providers</h3>
    <p class="text-[15px] pb-6">011Telecom, on its own, or through third-party providers, may collect information from
      the general website to better understand, reach, and direct potential customers to our website and our Services.
      This may include data collected to improve our Search Engine Optimization (SEO). 011Telecom may collect personal
      data about you for these purposes and in relation to such activities. 011Telecom may use such data (collected via
      cookies and other technologies) to plan and manage our advertising on this and other sites or to provide you
      offers or advertisements based upon your browsing or landing page interest and inquiries. If you “follow” or
      “like” 011Telecom on third-party social media sites, 011Telecom will see your public profile and may direct
      advertising to you through social media.</p>

    <h3 class="font-semibold text-base">Data We Collect to Render Our Software as a Service & Mobile App</h3>
    <p class="text-[15px] pb-6">011Telecom collects personal data directly from Licensed Users and prospect inquiries to
      our Services. We also collect data from interactions on our website and VNumber℠ Mobile App. 011Telecom may
      collect personal data from additional sources, and we may combine this data with data we already have about you to
      transact business with you. This personal data may include the following:</p>

    <h3 class="font-semibold text-base">User Contact Details</h3>
    <p class="text-[15px] pb-6">011Telecom may collect your contact details as Licensed Users, such as your name,
      company, address, primary contact telephone number, and email address. In the software Services provided to you,
      011Telecom may collect username (if more than one authorized user), email address, and associated IP address as
      part of our Services to keep proper records for use and billing.</p>

    <h3 class="font-semibold text-base">Account Creation and Use</h3>
    <p class="text-[15px] pb-6">When you create a 011Telecom account, we collect your user’s contact details and a
      password for service and support, including verifications and password resets. 011Telecom may further collect
      information about your logins and how you interact with the Platform, functions commonly used, and our software
      services performance to improve our VNumber℠ Service and the Licensee user’s experience. This includes features
      you use, click, upload, organize, as well as data migrations and log information about the Services you use or
      regularly use from your account.</p>

    <h3 class="font-semibold text-base">Billing Data</h3>
    <p class="text-[15px] pb-6">When you access a Licensed User account for use of our functionality and Platform’s
      software features, 011Telecom may collect billing and payment information. For automated and credit card payments,
      we use a third-party payment processor to process the payment. We do not collect your credit card number,
      expiration date, or PIN number for any other purpose. The transaction details we retain are those details that
      allow us to verify that you paid for Licenses, usage credits/tokens, and the Services or transaction, including
      price, currency, date of payment, payment method, name, and account name. That information is combined with your
      Licensed VNumber℠ Account information.</p>

    <h3 class="font-semibold text-base">Support Data</h3>
    <p class="text-[15px] pb-6">Data, content, and feedback given to 011Telecom through its customer support or any chat
      functionality available on our website, within our Services, or other sites that we control may be collected for
      support and operational purposes. This may include information about your technology, internet connection,
      screenshots, or any other data used for troubleshooting problems or improving our platform, software, or
      functionality.</p>

    <hr>
    <h3 class="font-semibold text-base">Data We Do Not Collect</h3>
    <p class="text-[15px] pb-6">011Telecom does not collect certain personal data directly from, or on behalf of, its
      Licensed Users for business and service purposes. This personal data may include the following:</p>

    <h3 class="font-semibold text-base">Licensee’s Client Contact Data</h3>
    <p class="text-[15px] pb-6">The information that comprises your client’s contact data (e.g., name, telephone
      numbers, emails, consents, and other personalized information used to manage your contact data) is derived
      strictly from your interaction and contact with your client. This includes physical documentation you maintain in
      your business location or information electronically stored by you and entered into our system after the client’s
      expressed written consent to be contacted. This is information that is always strictly under your control.
      011Telecom shall treat this as aggregated data for organizational and contact information processing in on-demand
      contact messaging you send. Upon termination or license expiration of 011Telecom’s dealings with the Licensee, the
      Licensee will be given a reasonable opportunity to download any client content data in a portable form, and any
      data on our Platform will thereafter be destroyed. This includes the groups, subgroups, or special groups you
      customize on the Platform. 011Telecom likewise does not independently acquire, develop, generate, or otherwise
      provide Licensees any client contact data. 011Telecom does not resell your client contact data. Your data was
      developed by you within the context of your business and is yours exclusively. 011Telecom has no part in the
      collection and acquisition of this data.</p>

    <h3 class="font-semibold text-base">Migrated or Number Porting Contact Data</h3>
    <p class="text-[15px] pb-6">Any information uploaded by you or migrated on processing demand to 011Telecom that
      comprises your client’s contact data is your data, developed by you within the context of your business within the
      Account, and is yours exclusively. It is treated by 011Telecom, both before and after uploading or after being
      migrated, as such.</p>

    <h3 class="font-semibold text-base">Verification of Contact</h3>
    <p class="text-[15px] pb-6">Any time 011Telecom processes and verifies a contact of yours, or from your VNumber℠
      Account, or from lists of your client contact data for use of VNumber℠, it retains no information in the
      processing of that request other than the simple result of verification. The information that comprises the client
      contact data before and after verification is your data, developed by you within the context of your business and
      is yours exclusively.</p>

    <hr>

    <h3 class="font-semibold text-base">Data Under Legal Obligations</h3>
    <h3 class="font-semibold text-base">California Privacy Rights and Customers’ Contact Data</h3>
    <p class="text-[15px] pb-6">Under the California Consumer Privacy Act (“CCPA”) (Cal. Civ. Code § 1798.100, et seq.),
      California residents have specific rights regarding the use of their personal information. 011Telecom operates as
      a Platform-as-a-Service provider that provides software and data processor with respect to a Licensed User and the
      Licensed User’s customer contact data. 011Telecom only uses such data for the purpose of providing its Platform
      and software functionality to its Licensed Users. 011Telecom may access Customers’ Contact Data to provide our
      VNumber℠ Services, to address technical or other service problems, or to comply with instructions of our Customers
      who provided the data. 011Telecom does not access, retain, use, or disclose Customers’ Contact Data for any other
      purpose.</p>

    <h3 class="font-semibold text-xl">Lawful Purposes and Lawful Legal Requests</h3>
    <p class="text-[15px] pb-6">Where required by law, or 011Telecom determines it is necessary to protect our legal
      rights and reasonable business interests (including the protection of intellectual property of 011Telecom under
      VNumber℠), or the legal rights of others impacted by your use of our Services in ways not intended under your
      Limited License, we may also use information about you in connection with legal claims (ourselves or any third
      parties) and/or for purposes of regulatory compliance. Personal data may be used for activities where your use
      impacts our reasonable business interests and as permitted or required by law. This includes lawful legal requests
      from the courts, under lawful subpoena, or by law enforcement authorities and agencies. If we generate a marketing
      list based on inquiries or information you provide us with consent, you may request to be deleted or removed from
      the list at any time either temporarily or permanently. 011Telecom may disclose personal data to prevent or stop
      an attack on our platform or network or to prevent attempts to defraud our users or breach our security protocols.
      011Telecom may disclose your data in connection with any merger, financing, acquisition, bankruptcy, dissolution,
      transaction, or proceeding involving the sale, transfer, divestiture, or disclosure of all or a portion of our
      business or assets to another company.</p>

    <h3 class="font-semibold text-base">Prohibited Content on Our Platform</h3>
    <p class="text-[15px] pb-6">Our Platform and Service are meant for businesses to better engage the existing
      customers they know and value. Protection of the rights of parties messaged is just as important as the use of our
      Platform by our clients. Blind telemarketing through text messages or business that runs counter to general
      consumer interests is not intended to be used with our Service. The following categories of messages are strictly
      prohibited on our Platform. If 011Telecom determines, in its sole informed opinion, that such messaging as found
      below is being transmitted by a user of our Platform, it will then be necessary for us to protect our legal rights
      in the Platform and reasonable business interests, and either immediately block the functionality of messaging
      from your Account, or suspend your Account pending a full investigation of any possible misuse of our Services.
      This is a zero-tolerance policy. These types of messages include the following content:</p>

    <h3 class="font-semibold text-base">Marketing of High-Risk Financial Services & Use in Collections is Restricted in Mass-Calling or Mass-Texting Basis</h3>
    <p class="text-[15px] pb-6">The following are prohibited from being used with your VNumber℠ or VNumber℠ Service for campaigns or mass-texts or calls:</p>
    <ul class="ml-4 list-disc">
      <li>Payday Loans</li>
      <li>Short-Term High-Interest Loans</li>
      <li>Auto Loans</li>
      <li>Mortgage Loans</li>
      <li>Student Loans</li>
      <li>Debt Collection</li>
      <li>Gambling/Sweepstakes</li>
      <li>Stock Alerts</li>
      <li>Cryptocurrency</li>
    </ul>

    <h3 class="font-semibold text-base">High-Risk Business Opportunity Offers and Multi-Level Programs</h3>
    <ul class="ml-4 list-disc">
      <li>Deceptive Work from Home Programs</li>
      <li>Risk Investment Opportunities</li>
      <li>Multi-Level Marketing</li>
    </ul>

    <h3 class="font-semibold text-base">Third-Party Telemarketing Solicitations</h3>
    <ul class="ml-4 list-disc">
      <li>Debt Collection or Consolidation</li>
      <li>Debt Reduction</li>
      <li>Credit Repair Programs</li>
      <li>Lead Generation</li>
    </ul>

    <h3 class="font-semibold text-base">Controlled Substance Advertisements or Solicitations</h3>
    <ul class="ml-4 list-disc">
      <li>Tobacco</li>
      <li>Vape</li>
      <li>Federally Illegal Drugs</li>
    </ul>

    <h3 class="font-semibold text-base">Fraudulent, Offensive, or Abusive Messages or Image Links</h3>
    <ul class="ml-4 list-disc">
      <li>Abuse of Minors</li>
      <li>Pornography</li>
      <li>Profanity or Hate Speech</li>
      <li>Phishing</li>
      <li>Fraud or Scams</li>
      <li>Deceptive Marketing</li>
    </ul>
  </section>
</main>