<footer class="bg-[#333232] px-5 text-white">
  <div class="block mlg:flex justify-center py-10">
    <section class="flex flex-col w-full items-center mlg:items-start mlg:w-max mlg:pr-20 rtl:mlg:pl-20">
      <img src="/images/logo-w.webp" alt="Logo Desktop" class="w-[15rem] lg:w-[10rem] h-fit" loading="lazy">

      <div class="py-5 flex flex-col gap-2">
        <a href="mailto:info@vnumber.com" class="text-sm md:text-base hover:text-orange">info&#64;vnumber.com</a>
        <a href="mailto:support@vnumber.com" class="text-sm md:text-base hover:text-orange">support&#64;vnumber.com</a>
      </div>
      <!-- <div class="flex py-8 gap-5">
        <a href="https://www.facebook.com/people/Globusim/100057373460578/" target="_blank"><img src="/images/icons/facebook.svg" alt="facebook icon" class="w-[2rem] h-[2rem] img-rotate"></a>
        <a href="http://www.twitter.com/globusim" target="_blank"><img src="/images/icons/twitter.svg" alt="twitter icon" class="w-[2rem] h-[2rem] img-rotate"></a>
        <a href="http://www.youtube.com/@globusim6888" target="_blank"><img src="/images/icons/youtube.svg" alt="youtube icon" class="w-[2rem] h-[2rem] img-rotate"></a>
        <a href="http://www.instagram.com/globusim4travel" target="_blank"><img src="/images/icons/instagram.svg" alt="instagram icon" class="w-[2rem] h-[2rem] img-rotate"></a>
        <a href="https://www.linkedin.com/showcase/globusim/" target="_blank"><img src="/images/icons/linkedin.svg" alt="linkedin icon" class="w-[2rem] h-[2rem] img-rotate"></a>
      </div> -->
    </section>
    
    <section class="grid grid-cols-2 md:grid-cols-3 gap-2 lg:gap-6 py-4">
      <!-- CONTACT US -->
      <article class="flex flex-col items-center wide:px-8 md:min-w-[220px] pb-8">
        <h3 class="font-semibold pb-4" transloco="footer-vn.title-contact"></h3>
        <ul class="text-sm xl:text-base">
          <li class="cursor-pointer flex p-1"> <span transloco="footer-vn.contact-link-usa"></span>&nbsp;<a href="tel:+1-855-613-8188" class="hover:text-orange"> 1-855-613-8188</a> </li>
          <li class="cursor-pointer flex p-1"> <span transloco="footer-vn.contact-link-canada"></span>&nbsp;<a href="tel:+1-855-613-8188" class="hover:text-orange"> 1-855-613-8188</a> </li>
          <li class="cursor-pointer flex p-1"> <span transloco="footer-vn.contact-link-uk"></span>&nbsp;<a href="tel:+44-20-369-52-339" class="hover:text-orange"> 44-20-369-52-339</a> </li>
          <!-- <li class="cursor-pointer flex flex-col sm:flex-row"> <span transloco="footer.contact-link-israel"></span><a href="tel:+972-516-500-600">972-51-650-0600</a> </li> -->
        </ul>
      </article>
      <!-- THE COMPANY -->
      <article class="flex flex-col items-center wide:px-8 pb-8">
        <h3 class="font-semibold pb-4" transloco="footer-vn.title-company"></h3>
        <ul class="text-sm xl:text-base">
          <li class="cursor-pointer hover:scale-x-105 hover:text-orange" routerLink="about-us" transloco="footer-vn.company.about-us"> </li>
          <li class="cursor-pointer hover:scale-x-105 hover:text-orange" routerLink="contact-us" transloco="footer-vn.company.contact-us"> </li>
        </ul>
      </article>
      <!-- QUICK LINKS -->
      <article class="flex flex-col items-center wide:px-8 pb-8">
        <h3 class="font-semibold pb-4" transloco="footer-vn.title-links"></h3>
        <ul class="text-sm xl:text-base">
          <li class="cursor-pointer hover:scale-x-105 hover:text-orange" routerLink="faq" transloco="footer-vn.quick-links.faq"></li>
          <li class="cursor-pointer hover:scale-x-105 hover:text-orange" routerLink="privacy-policy" transloco="footer-vn.quick-links.privacy-policy"></li>
          <li class="cursor-pointer hover:scale-x-105 hover:text-orange" routerLink="terms-and-conditions" transloco="footer-vn.quick-links.terms"></li>
          <li class="cursor-pointer hover:scale-x-105 hover:text-orange" routerLink="retirees" transloco="footer-vn.quick-links.retirees"></li>
          <li class="cursor-pointer hover:scale-x-105 hover:text-orange"><a href="https://vnumber.tapfiliate.com/" target="_blank" rel="noopener noreferrer"  transloco="footer-vn.quick-links.affiliate"></a></li>
        </ul>
      </article>
    </section>
  </div>

  <!-- <span class="flex justify-center text-center w-full text-grey py-4" transloco="footer.copyright"></span> -->
</footer>