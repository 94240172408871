<button 
type="button" 
(click)="toggleVisibility()" 
class="" 
aria-label="Toggle password visibility">
  @if (!showPassword) {
    <img src="/images/icons/login/eye-off.svg" alt="" class="w-5">
  } @else {
    <img src="/images/icons/login/eye.svg" alt="" class="w-5">
  }
</button>