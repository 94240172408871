import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountriesService } from '../../../services/countries/countries.service';
import { FilterCountriesPipe } from '../../../pipes/filter-countries.pipe';
import { FlagsPipe } from '../../../pipes/flags.pipe';
import { ICountries } from '../../../models/countries.model';

@Component({
  selector: 'app-search-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    FlagsPipe
  ],
  providers: [FilterCountriesPipe],
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent {
  @Input() placeholder: string = '';
  @Input() type: string = ''; // vnum | place | rate

  @Output() selectedCountry = new EventEmitter<any>();

  @ViewChild('simSearchInput') simSearchInput!: ElementRef;

  countryControl = new FormControl('');
  dropdownVisible = false;
  searchValue: string = '';
  lowerPrice: number = 0;
  countries: any = [];
  filteredCountries: any = []; // New property to store filtered countries
  countryValue: string = ''
  selectedIndex: number = -1;

  constructor(
    private el: ElementRef,
    private countryService: CountriesService,
    private filterCountriesPipe: FilterCountriesPipe
  ) {}

  getCountries() {
    if (this.type === 'vnum') {
      this.getVNCountries();
    } else if (this.type === 'rate') {
      this.getRateCountries()
    } else {
      this.getPackageCountries();
    }
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
    if (this.dropdownVisible) {
      this.searchValue = "";
      this.updateFilteredCountries()
      setTimeout(() => {
        this.simSearchInput.nativeElement.focus();
      }, 200);
    }
  }

  closeDropdown() {
    setTimeout(() => {
      this.dropdownVisible = false;
    }, 200);
  }

  trackByFn(index: number, country: any): number {
    console.log(country)
    return country.countryId; // Retorna un identificador único, como el ID del país
  }

  async getVNCountries() {
    const res = await this.countryService.getDIDCountries();
    this.countries = res.result;
    this.updateFilteredCountries();
  }

  async getPackageCountries() {
    const res = await this.countryService.getPackageCountries();
    this.countries = res.result;
    this.updateFilteredCountries();
  }

  async getRateCountries() {
    try {
      const res = await this.countryService.getCountrySelection<ICountries[]>();

      if (res.isError) {
        console.error('ERROR: ', res.errorMessage);
        return;
      }

      if (res.isSuccessful) {
        this.countries = res.result;
      }

    } catch (error) {
      console.error(error)
    }

  }

  onSelectCountry(country: any) {
    this.searchValue = country.countryName;
    this.selectedCountry.emit(country);
    this.dropdownVisible = false;
    this.countryValue = country.countryName;
    this.selectedIndex = -1;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: any) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.searchValue = '';
      this.dropdownVisible = false;
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.searchValue = '';
      this.dropdownVisible = false;
    }

    if (this.dropdownVisible) {
      if (event.key === 'ArrowDown') {
        this.onArrowDown();
        event.preventDefault();
      } else if (event.key === 'ArrowUp') {
        this.onArrowUp();
        event.preventDefault();
      } else if (event.key === 'Enter') {
        this.onEnter();
        event.preventDefault();
      }
    }
  }

  onArrowDown() {
    if (this.selectedIndex < this.filteredCountries.length - 1) {
      this.selectedIndex++;
      this.scrollToView();
    }
  }

  onArrowUp() {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
      this.scrollToView();
    }
  }

  onEnter() {
    if (this.selectedIndex >= 0 && this.selectedIndex < this.filteredCountries.length) {
      this.onSelectCountry(this.filteredCountries[this.selectedIndex]);
    }
  }

  scrollToView() {
    const items = this.el.nativeElement.querySelectorAll('li');
    if (items[this.selectedIndex]) {
      items[this.selectedIndex].scrollIntoView({ block: 'nearest' });
      items[this.selectedIndex].focus();
    }
  }

  ngOnInit(): void {
    this.getCountries();

    this.countryControl.valueChanges.subscribe((value) => {
      if (this.countries.includes(value)) {
        this.selectedCountry.emit(value);
      }
    });
  }

  onSearchValueChange() {
    this.updateFilteredCountries();
    this.selectedIndex = -1; // Reset selected index when search value changes
  }

  updateFilteredCountries() {
    this.filteredCountries = this.filterCountriesPipe.transform(this.countries, this.searchValue);
  }
}
