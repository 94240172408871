<main class="p-10 md:px-16 mlg:px-36">
  <section
    class="bg-title-bg bg-no-repeat md:bg-[center_left_10rem] mlg:bg-[center_left_35rem] py-16 animate-fade-down">
    <h2 class="text-center text-4xl font-semibold">Terms & Conditions</h2>
  </section>

  <section class="animate-fade-up">
    <h3 class="font-semibold text-base">Introduction</h3>
    <p class=" text-[15px]">Please read all Terms of Use & Mobile Application License carefully before
      subscribing to a VNumber℠ (a virtual calling and call forwarding feature telephone number) and accessing or using
      the mobile application and its software service for VNumber℠. By accessing or using the Service, you agree to be
      bound by the terms and conditions set forth below. Any use of, or purchase through, this website is expressly
      conditioned on your acceptance of all terms and conditions stated herein and any others that may be updated and
      available on #VNumber | Expand horizons, stay local. <strong>DO NOT USE THIS CALL FORWARDING SERVICE, SOFTWARE OR
        PLATFORM, OR OTHERWISE ACCESS 011TELECOM'S VNUMBER℠ SERVICES THROUGH THIS WEBSITE AND PLATFORM</strong>, if you
      do not
      accept all the terms and conditions stated on this website.</p>

    <p class="text-[15px] pb-6">Subject to terms and conditions shown below, <strong>011Telecom, Inc. (FL)
        (“011Telecom”)</strong> grants
      the subscriber a single non-exclusive month-to-month limited use license (the “License”) for the VNumber(s)℠ and
      Mobile Application subscribed through it, that may not be sub-licensed, transferred, or assigned by the subscriber
      to use the calling feature groups or access the mobile application and/or <strong>011Telecom</strong>’s website to
      order, to buy
      virtual and internet communication services, or services that include the option to participate in promotions and
      customer reward programs. <strong>011Telecom</strong> reserves the right to suspend, limit, or cancel this license
      subject to the
      following terms and conditions of the License. <strong>011Telecom</strong> reserves the right to modify the terms
      and conditions of
      this license at any time.</p>

    <p class="text-[15px] pb-6">Under access and use of this application, you agree to be bound by all terms and
      conditions set out below. Any use or purchase through this application is expressly conditioned on your compliance
      with these terms and conditions as established hereunder and as these terms are occasionally upgraded by
      <strong>011Telecom. DO NOT SIGN UP FOR THIS APPLICATION, USE THE SERVICES, CREATE AN ACCOUNT OR ACQUIRE
        INTERNATIONAL
        CALLING SERVICES IN THIS APPLICATION</strong> if you do not accept any of the terms and conditions indicated
      <strong>BELOW</strong>. These
      terms and conditions and any other offered unit are subject to The Electronic Signatures in Global and National
      Commerce Act (E-sign Act 2000), as amended, and according to it constitute an agreement between
      <strong>011Telecom</strong> and the
      VNumber℠ Account User and not for anyone else. Accepting this mobile application download by choosing “I accept”
      in the downloading screen means user consent. <strong>WHEN YOU ACTIVATE YOUR 011TELECOM ACCOUNT OR USE ANY OF THE
        SERVICES, IT REPRESENTS THAT YOU ARE OF LEGAL AGE TO ACCEPT THIS AGREEMENT AND THAT YOU HAVE READ AND COMPLETELY
        UNDERSTAND THE TERMS AND CONDITIONS FOR THIS AGREEMENT AND APPLICATION USE.</strong>
    </p>

    <h3 class="font-semibold text-base">Warning Against Unlawful Use</h3>
    <p class="text-[15px] pb-6"><strong>WARNING:</strong> It is a Federal <strong>AND</strong> State Offense to use
      communication services or text
      messages for any fraudulent means. Any and all Services furnished or performed through this platform shall not be
      used for any unlawful purpose.</p>

    <h3 class="font-semibold text-base">011Telecom - VNumber℠ License & Terms of Use</h3>
    <h3 class="font-semibold text-base">Acceptance of Terms</h3>
    <p class="text-[15px] pb-6">These Terms of Purchase constitute an agreement ("Agreement") between
      <strong>011Telecom, Inc.</strong>
      and its branded VNumber℠ Software as a Service ("<strong>011Telecom</strong>," “we,” or “us”) and the purchaser
      (“Customer” or
      “you”) for residential and small business products offered by <strong>011Telecom</strong> ("Service") and where an
      electronic or
      written Agreement does not exist for services purchased from <strong>011Telecom</strong> and purchased by the
      Customer through this
      website.
    </p>

    <p class="text-[15px] pb-6"><strong>BY PURCHASING THROUGH THIS WEBSITE, YOU REPRESENT THAT YOU ARE OF LEGAL AGE TO
        ENTER
        INTO THIS AGREEMENT AND THAT YOU HAVE READ AND FULLY UNDERSTAND THE TERMS AND CONDITIONS OF THIS
        AGREEMENT.</strong></p>

    <p class="text-[15px] pb-6">By accessing this website or purchasing products therein, you agree to be bound by the
      terms and conditions set forth below. If you do not wish to be bound by these terms and conditions, do not
      purchase communications feature services through this website. If you utilize the Products offered in a manner
      inconsistent with these terms and conditions, <strong>011Telecom</strong> may unilaterally terminate access, block
      your future
      access, and/or seek such additional relief as the circumstances of your misuse indicate is proper.</p>

    <h3 class="font-semibold text-base">1. Governing Law, Jurisdiction & Venue</h3>
    <p class="text-[15px] pb-6">These Terms of Use shall be governed exclusively and construed in strict accordance with
      the laws of the State of Florida. Customer, by virtue of purchase through this website, agrees to submit to
      exclusive jurisdiction and venue of the state and federal courts of Broward County, Florida, and expressly waives
      all defenses to jurisdiction without any consideration of conflict of law theories, case law, or principles.</p>

    <h3 class="font-semibold text-base">2. Electronic Contract for Service</h3>
    <p class="text-[15px] pb-6">All services, including licensed use of VNumber℠ Software as a Service and Mobile
      Application, are agreed upon by electronic format contract that exclusively governs their terms and requirements.
      011Telecom shall make available your agreement in electronic format upon request. You may request access to these
      electronic records in either Adobe Acrobat or standard text formats. At all times, you retain the right to request
      and receive documents on paper upon request to 011Telecom via email.</p>
    <p class="text-[15px] pb-6">Once the SIM’s U.S. or Canadian phone number of the SIM is disconnected due to lack of
      funds on the SIM, the new number will be assigned at the time the SIM is recharged again.</p>

    <h3 class="font-semibold text-base">3. Grant of Limited License of Use</h3>
    <p class="text-[15px] pb-6">Subject to these Terms and Conditions, <strong>011Telecom</strong> hereby grants to you,
      the Customer,
      and only the Customer (as named in your <strong>011Telecom</strong> account), a non-assignable, non-transferable,
      limited license
      and right to access and use the Virtual call forward number (VNumber℠) on a Software-as-a-Service (SaaS) call
      feature group basis inclusive of its pre-developed software, functions and methodologies, and features, the rights
      to which the Customer exclusively agrees vest in <strong>011Telecom</strong>. Under this license, Customer is able
      to provision,
      operate, and manage their respective calls, messages, and other ancillary call features from
      <strong>011Telecom</strong>’s virtual
      platform for their respective VNumber(s)℠ insofar as <strong>011Telecom</strong>’s fees and all associated costs,
      charges, and
      expenses derived from the Customer’s use of the SaaS Solution as well as call forwarding charges for the carriage
      of transmission are paid or maintain a positive prepaid monetary balance with <strong>011Telecom</strong> under
      these Terms and
      Conditions.
    </p>

    <h3 class="font-semibold text-base">4. Licensed Accounts</h3>
    <p class="text-[15px] pb-6"><strong>011Telecom</strong> shall provide Customer with a dedicated Account, Account
      Profile, Login, and
      password credentials to enable Client to use the SaaS under the Limited License of Use. The account types shall
      vary based upon the volume and functionality of use the Customer desires. Charges of <strong>011Telecom</strong>
      for expanded
      account volumes and use shall be established in pricing, which may be revised from time to time by
      <strong>011Telecom</strong> at
      its sole discretion. <strong>011Telecom</strong> reserves the right to expand or restrict functionality of its
      VNumber℠ service and
      their use of the SaaS for logistical, technical, and regulatory compliance purposes. <strong>011Telecom</strong>
      is an integrated
      IP Enabled Communication Service Provider that offers call forwarding services to existing subscriber numbers of a
      user. <strong>011Telecom</strong> has existing providers and carrier(s) connected to its network that undertake
      the transmission
      and re-transmission of on-demand Mobile App calling, call forwarding functions, and text messaging as a part of
      the VNumber℠ service. The expense these carriers charge is charged to the Customer by means of the prepaid
      balances that <strong>011Telecom</strong> maintains on a fixed basis based upon normal consumer and business use
      of the Service.
      <strong>011Telecom</strong> reserves the sole right to increase or decrease the amount of that balance or prepaid
      amount to reflect
      then-current rates or charges of its carriers/providers connected to its platform who render the service charge.
      Please check pricing before using VNumber℠ features to ensure sufficient balances for desired use of the VNumber℠.
    </p>

    <h3 class="font-semibold text-base">5. Limited License of Use Restrictions</h3>
    <p class="text-[15px] pb-1">Customer shall not, nor shall it permit or assist others, to:</p>
    <ul class="pl-2 list-disc">
      <li>Modify or fraudulently use their VNumber℠, or the 011Telecom SaaS platform or Software from the Customer’s
        Account.</li>
      <li>Access and de-engineer <strong>011Telecom</strong>’s software or Proprietary Materials that enable use of
        their VNumber(s)℠ or
        its SaaS Services for any purpose other than those expressly authorized.</li>
      <li>Process or permit to be processed the data of any third party that is not expressly authorized herein to
        access.</li>
      <li>Violate any of the following agreed conditions of use.</li>
      <ul class="pl-2 list-disc">
        <li>You are solely responsible for maintaining the confidentiality and security of your account number and/or
          password used with your VNumber(s)℠.</li>
        <li>You are responsible for all uses of your account, whether or not actually or expressly authorized by you.
          Any unauthorized access of the Customer’s account that is the result of the Customer’s negligent handling of
          any account number or password is expressly the liability of the Customer.</li>
        <li>You expressly waive all legal defenses and relief against <strong>011Telecom</strong> for any collectable
          debt that arises
          from the unauthorized access of their services, by and through your Password, PIN, and/or online VNumber℠
          account, that results from your own mishandling, negligence, or disclosure of your VNumber℠ account number or
          password as well as any automatic access to the Mobile App you may set on your cellular telephone for the App
          in the event the device is stolen or lost.</li>
      </ul>
      <li><strong>Unlimited Texting within the Mobile App:</strong> You may send and receive unlimited text messages
        from within the Mobile App on a monthly basis. The VNumber℠ Account, however, is not intended to send text
        messages en masse or for commercial marketing campaigns. If 011Telecom discovers a pattern of text messages that
        is otherwise for a commercial use en masse, at its sole discretion, it may suspend or terminate the VNumber℠ or
        VNumber℠ Account for misuse. VNumber℠ unlimited texting is intended for personal use for personal and individual
        business purposes.</li>
      <li><strong>Toll-Free Accessibility within the Contiguous 48 States:</strong> Through the Mobile Application, you
        can call or receive forwarded calls from Toll-Free Numbers in the continental United States from within the
        Mobile App and your VNumber℠. However, there is no accessibility within the Mobile App to Toll-Free numbers
        outside this area including Alaska, Hawaii, and Puerto Rico.</li>
      <li><strong>Number Portability:</strong> You may port an existing number you subscribe to be your VNumber℠ within
        the Mobile App and VNumber℠ Account. Should you choose this feature, please contact our technical support team
        in addition to any choice to do so made within the Mobile App.</li>
      <li><strong>VNumber℠ is Not Intended to Replace Your Primary Cellular or VoIP Telephone – No E-911 or Similar
          Local Telephone Features:</strong> The VNumber℠ is purely a virtual number used to make receiving and making
        calls and text messages more convenient. It is not intended to replace your cellular service, VoIP phone, or any
        ordinary regular telephone service. For this reason, you expressly understand and agree that neither your
        VNumber℠ nor VNumber℠ Account, nor downloaded VNumber℠ Mobile App is designed or intended to support emergency
        calls to hospitals, law enforcement agencies, medical care units, or any other form of emergency service. You
        acknowledge and agree that 011Telecom is not obligated to provide emergency services in accordance with any
        local or national rules, regulations, or laws. You agree that VNumber℠ does not serve as a substitute for any
        primary telephone service you use or have access to for such purposes.</li>
    </ul>

    <h3 class="font-semibold text-base">6. Customer’s Compliance with Commercial Call/Text Message Laws &
      Rules</h3>
    <p class="text-[15px] pb-6">As a material condition of these Terms of Use of <strong>011Telecom</strong>’s VNumber℠
      and SaaS Service,
      Customer agrees that for any use of the system to contact the telephone numbers of subscribers in the United
      States, the state and federal laws apply (including the laws of the State of Florida) and govern
      telemarketing/telephone solicitation and may apply to any use of the VNumber℠ for commercial purposes (“TCPA
      Laws”). Customer agrees that the Federal Telephone Consumer Protection Act of 1991, specifically 47 USC §
      227(c)(5)(C) and 16 CFR § 310.4(b)(3) and 47 U.S.C. § 227(b)(1)(A) (“TCPA”), is a material compliance obligation
      of the Customer that must be observed prior to and when using the VNumber℠ for commercial purposes. This
      obligation includes call spoofing or obscuring compliance with these laws, or for a fraudulent purpose through the
      use of their VNumber℠. Customer agrees to abide by these laws and regulations as a material condition of its use
      of the VNumber℠ and all SaaS aspects of the Mobile Application.</p>

    <h3 class="font-semibold text-base">7. Prohibited Use to Make Threats</h3>
    <p class="text-[15px] pb-6">Customer agrees not to use their VNumber(s)℠ to make any threats of violence, personal
      harm, or any threat or statement of mass-harm that may be otherwise lawfully prohibited and punishable by civil
      and criminal laws of the place where such threat is directed through a call or message.</p>

    <h3 class="font-semibold text-base">8. Prohibited Use in the Commission of a Crime</h3>
    <p class="text-[15px] pb-6">Customer agrees to use their VNumber℠ for lawful purposes only. VNumber℠ is intended
      only for the convenience and ease of better personal and business communications service of its users through the
      use of its features in a lawful manner.</p>
    <p class="text-[15px] pb-6">Should <strong>011Telecom</strong> discover that VNumber(s)℠ are being used for purposes
      listed above, or
      similar purposes, it reserves the sole right to immediately suspend or terminate the Customer’s account and access
      to all VNumber(s)℠. Any records of use related to such offenses and suspension or termination of the account will
      be retained by <strong>011Telecom</strong> to respond to any lawful legal requests by affected parties or
      appropriate law
      enforcement agencies.</p>

    <h3 class="font-semibold text-base">9. VNumber℠ Service and Charges</h3>
    <p class="text-[15px] pb-6">VNumber℠ is a virtual telephone number that provides call forwarding features and
      functionality to receive and make calls through its Mobile Application and SaaS. The Service offers the User
      several different features within the Platform for the use and management of their virtual number.</p>

    <h3 class="font-semibold text-base">Features of the Service</h3>
    <ul class="pl-2 list-disc">
      <li><strong>Numerous VNumber(s)℠ in Various Area Codes:</strong> VNumber(s)℠ are offered in multiple area codes
        throughout the United States and internationally. Please refer to our website for an up-to-date list before
        purchasing our service.</li>
      <li><strong>Flexible Call Forwarding and Talk Time through Mobile Application:</strong> Through your VNumber℠
        Account and the Mobile Application, you can make and receive calls through your VNumber℠. You can also forward
        calls from another local number to your VNumber℠ either within the U.S. or internationally, and receive and make
        these calls directly through the App while you travel, work virtually, or use your cellular phone while
        on-the-go for business and personal necessity. There are a few restrictions and independent charges that may be
        applicable to this functionality for calls not done entirely through the Mobile Application.</li>
      <li><strong>Call Forwarding Outside the Mobile Application:</strong> Outbound Calling is available only through
        the VNumber℠ Mobile Application. However, from your VNumber℠ Account, you can call forward to any telephone
        number you subscribe to receive calls. If you purely call forward, charges for airtime, local phone charges, and
        associated fees charged by your provider or mobile provider shall apply under the terms of that telephone
        number’s service and subscription. Please be aware of this. There is no calling feature associated with VNumber℠
        outside call forwarding available outside of using the Mobile Application.</li>
      <li><strong>Dedicated Mobile App:</strong> Once uploaded onto your cellular phone, and account information
        completed and processed by <strong>011Telecom</strong>, the App will be dedicated to your use of VNumber(s)℠
        exclusively. This
        includes text messages and phone calls. The Mobile App is not part of any Social Network or Messaging Group
        system and will be dedicated to your personal and business use exclusively. For this reason:</li>
    </ul>
    <h3 class="font-semibold text-base">10. Billing and Charges</h3>
    <p class="text-[15px] pb-6">You may access and review applicable usage rates and feature pricing on our website.
      Multiple flexible plans are offered by <strong>011Telecom</strong>. However, fixed plans for basic service of
      VNumber℠ are
      available on a prepaid monthly basis. All charges and rates applicable to your VNumber℠ and VNumber℠ Mobile App
      usage shall be those posted on our website or within the Mobile App, and <strong>011Telecom</strong> reserves the
      right to change
      pricing from time to time at its sole discretion based upon service costs and administrative expenses associated
      with VNumber℠, with or without individual notice to the licensed user.</p>

    <h3 class="font-semibold text-base uppercase">11. Payment of Billing and Charges</h3>
    <p class="text-[15px] pb-6 uppercase">The Customer is responsible for paying all charges posted to its account or in
      an individual purchase, including charges such as shipping and handling, taxes, and other charges related to the
      particular transaction, on purchases through this website. Charges, including disputed amounts, must be paid by
      the date shown on the invoice or shipping invoice. If <strong>011Telecom</strong> accepts late or partial payments
      or payments
      marked "Paid in Full" or similar notations, it will not waive any of <strong>011Telecom</strong>’s rights
      hereunder nor will it
      constitute an accord or satisfaction.</p>

    <h3 class="font-semibold text-base uppercase">12. Billing/Charge Dispute Process</h3>
    <p class="text-[15px] pb-6 uppercase">To dispute VNumber℠ charges of any kind, you MUST notify
      <strong>011Telecom</strong> via email
      to support&#64;vnumber.com within seven (7) days of receiving your statement. <strong>011Telecom</strong>, at its
      sole discretion, will
      review whether there is cause for a refund or credit, or if the request is unfounded and denied. VNumber℠ charge
      credits may be offered by <strong>011Telecom</strong> in lieu of refund for any issue either with or without
      cause, at its sole
      discretion.</p>

    <h3 class="font-semibold text-base">13. No Refunds Without Cause</h3>
    <p class="text-[15px] pb-6"><strong>011Telecom</strong> will not offer any refunds or reimbursements without cause.
      Should Customers
      experience problems with their VNumber℠ Service or functionality, they must first contact our Customer service
      line at 1-877-993-9770 or email us directly at support&#64;vnumber.com to determine the nature of the problem or
      whether a refund is applicable. <strong>011Telecom</strong> exclusively reserves the right to determine whether a
      request for
      refund is with or without cause.</p>

    <h3 class="font-semibold text-base">14. No Warranties on Service</h3>
    <p class="text-[15px] pb-6"><strong>011TELECOM DOES NOT MAKE ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
        INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE WITH REGARD TO
        VNUMBER℠ OR ITS SOFTWARE AS A SERVICE. 011TELECOM SHALL NOT BE LIABLE FOR ANY COST OR DAMAGE ARISING EITHER
        DIRECTLY OR INDIRECTLY FROM ANY SUCH TRANSACTION, USE OF THE SERVICE OR THE SOFTWARE. 011TELECOM DOES NOT
        WARRANT THAT VNUMBER℠ OR ITS SOFTWARE AS A SERVICE WILL BE ERROR-FREE OR WILL OPERATE WITHOUT FAILURE OR
        INTERRUPTION NOR THAT ANY DEFECTS IN THE PRODUCTS OR MATERIALS WILL BE CORRECTED BY THE MANUFACTURER UNDER
        WARRANTY. CUSTOMER MUST DEAL DIRECTLY WITH MANUFACTURER FOR SUCH ISSUES. 011TELECOM SPECIFICALLY DISCLAIMS ALL
        REPRESENTATIONS AND WARRANTIES ASSOCIATED WITH:</strong></p>
    <ul class="pl-2 list-disc">
      <li><strong>011TELECOM’S EQUIPMENT, FACILITY, OR NETWORK FAILURE; NETWORK MODIFICATIONS OR UPGRADES, OUTAGES DUE
          TO NETWORK RELOCATION, ACTS OR OMISSIONS OF VENDORS, SERVICE PROVIDERS, UNDERLYING PROVIDERS, OR THIRD
          PARTIES.</strong></li>
      <li><strong>IMPEDIMENTS CAUSED BY THIRD PARTIES OR UNDER FORCE MAJEURE EVENTS.</strong></li>
      <li><strong>ACTS OR OMISSIONS BY YOU ON THE DEVICE ON WHICH THE MOBILE APP IS DOWNLOADED, INCLUDING DOWNLOAD OR
          DATA CORRUPTION DUE TO LIMITATIONS ON YOUR DEVICE.</strong></li>
    </ul>
    <h3 class="font-semibold text-base">15. Express Limitation of Liability</h3>
    <p class="text-[15px] pb-6"><strong>IN NO EVENT WILL 011TELECOM BE LIABLE TO THE CUSTOMER FOR (I) ANY INCIDENTAL,
        CONSEQUENTIAL, OR INDIRECT DAMAGES (INCLUDING, BUT NOT LIMITED TO DAMAGES FOR LOSS INTERRUPTION, OR LOSS OF
        PROPERTY, LOSS OF DATA, BREACH OF PERSONAL INFORMATION, AND THE LIKE) ARISING OUT OF THE USE OF OR INABILITY TO
        USE VNUMBER℠ OR ITS SOFTWARE AS A SERVICE, AND SERVICES SOLD THROUGH THIS WEBSITE, EVEN IF 011TELECOM OR ITS
        AUTHORIZED REPRESENTATIVES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR (II) ANY CLAIM ATTRIBUTABLE
        TO ERRORS, OMISSIONS, OR OTHER INACCURACIES IN THE PRODUCTS AND/OR MATERIALS SOLD THROUGH THIS WEBSITE OR THE
        VNUMBER℠, THE VNUMBER℠ MOBILE APP OR ITS SOFTWARE AS A SERVICE.</strong></p>
    <h3 class="font-semibold text-base">16. Indemnification</h3>
    <p class="text-[15px] pb-6">The Customer agrees to indemnify, defend, and hold <strong>011Telecom</strong>, its officers, directors,
      employees, agents, shareholders, licensors, suppliers, business partners, and any beneficial owners from and
      against all losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting from Customer’s
      use of any Products or Devices, including, without limitation, any violation of the License and these Terms of Use
      by Customer. The provisions of this paragraph are for the benefit of <strong>011Telecom</strong> and its officers, directors,
      employees, agents, shareholders, licensors, suppliers, business partners, and beneficial owners in regard to the
      Products or Materials sold through this website. Each of these individuals or entities shall have the right to
      assert and enforce these provisions directly against Customer on its own behalf.</p>
    <h3 class="font-semibold text-base">17. Trademarks</h3>
    <p class="text-[15px] pb-6">All corporate names, service marks, logos, trade names, trademarks (including the
      VNumber℠ mark), websites, and domain names of <strong>011Telecom</strong> (collectively "Marks") are and shall remain the exclusive
      property of <strong>011Telecom</strong>, and nothing in this agreement shall grant Customer or any third party the license to use
      such Marks. All service marks and trademarks of manufacturers are and shall remain exclusively their own, and
      their appearance on this website shall not be construed in any way to grant or vest the Customer or any visitor a
      license or right to copy or use such marks from this website. Such marks are used only to offer and sell the
      Products of the manufacturers.</p>
    <h3 class="font-semibold text-base">18. Copyright</h3>
    <p class="text-[15px] pb-6">All Content and Materials found at this Website, including the VNumber℠ mark, works, and
      image, are protected under U.S. Copyright or other intellectual property laws. Any commercial use of the VNumber℠
      Service, supporting Software, Mobile Application, the Look and Feel in presentation of such under VNumber℠
      Service, <strong>011Telecom</strong> software used in the VNumber℠ Mobile App and Materials found on the Website is strictly
      prohibited without the express, prior, written consent of <strong>011Telecom</strong>. Any reproduction or redistribution of the
      Services and/or Materials not in accordance with the terms set forth herein is expressly prohibited by law and may
      result in severe civil and criminal penalties.</p>
  </section>
</main>