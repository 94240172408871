import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';

interface FAQItem {
  q: string;
  a: string;
}

@Component({
  selector: 'app-faq-accordion',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './faq-accordion.component.html',
  styleUrls: ['./faq-accordion.component.scss'],
})
export class FaqAccordionComponent {
  @Input() faqSrc: string = '';

  faqItems: FAQItem[] = [
    {
      q: 'faq.question-one',
      a: 'faq.answer-one',
    },
    {
      q: 'faq.question-two',
      a: 'faq.answer-two',
    },
    {
      q: 'faq.question-three',
      a: 'faq.answer-three',
    },
    {
      q: 'faq.question-four',
      a: 'faq.answer-four',
    },
    {
      q: 'faq.question-five',
      a: 'faq.answer-five',
    },
    {
      q: 'faq.question-six',
      a: 'faq.answer-six',
    },
    {
      q: 'faq.question-seven',
      a: 'faq.answer-seven',
    },
    {
      q: 'faq.question-eight',
      a: 'faq.answer-eight',
    },
    {
      q: 'faq.question-nine',
      a: 'faq.answer-nine',
    },
    {
      q: 'faq.question-ten',
      a: 'faq.answer-ten',
    },
    {
      q: 'faq.question-eleven',
      a: 'faq.answer-eleven',
    },
    {
      q: 'faq.question-twelve',
      a: 'faq.answer-twelve',
    },
    {
      q: 'faq.question-thirteen',
      a: 'faq.answer-thirteen',
    },
    {
      q: 'faq.question-fourteen',
      a: 'faq.answer-fourteen',
    },
    {
      q: 'faq.question-fifteen',
      a: 'faq.answer-fifteen',
    },
  ];

  constructor() {}

  faqSelected: number = -1;

  collapseFaq(index: number) {
    if (index !== this.faqSelected) {
      this.faqSelected = index;
    } else {
      this.faqSelected = -1;
    }
  }

  ngOnInit(): void {}
}

