import { Component, inject } from '@angular/core';
import { SearchInputComponent } from '../search-input/search-input.component';
import { BtnPrimaryComponent } from '../buttons/btn-primary/btn-primary.component';
import { FormGroup } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { SubmitOrderService } from '../../../services/submit-order/submit-order.service';
import { IDidCountries, IPackageCountries } from '../../../models/countries.model';

@Component({
  selector: 'app-search-countries',
  standalone: true,
  imports: [SearchInputComponent, BtnPrimaryComponent, TranslocoModule],
  templateUrl: './search-countries.component.html',
  styleUrl: './search-countries.component.scss'
})
export class SearchCountriesComponent {
  virtualNumberCountry: IDidCountries | null = null;
  packageCountry: IPackageCountries | null = null;
  submitOrderService = inject(SubmitOrderService);

  countriesForm = this.submitOrderService.submitForm.get('countriesForm') as FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.countriesForm.reset()
  }

  onVirtualNumberCountrySelected(country: IDidCountries) {
    this.countriesForm.get('virtualNumberCountryName')?.setValue(country.countryName);
    this.countriesForm.get('virtualNumberCountryId')?.setValue(country.countryID);
    this.virtualNumberCountry = country
  }

  onPackageCountrySelected(country: IPackageCountries) {
    this.countriesForm.get('packageCountryName')?.setValue(country.countryName);
    this.countriesForm.get('packageCountryId')?.setValue(country.countryId);
    this.packageCountry = country
  }

  getLink(): string {
    const countryName = this.virtualNumberCountry?.countryName?.toLowerCase().replace(" ", "-");
    const countryId = this.virtualNumberCountry?.countryID;
    const packageCountry = this.packageCountry?.countryName?.toLowerCase().replace(" ", "-");
    return `/choose-your-number/${countryName}/${countryId}/${packageCountry}/${this.packageCountry?.iso2}/${this.packageCountry?.packageId}`;
  }
}
