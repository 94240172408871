import {
  Component,
  computed,
  inject,
  Signal,
  signal,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
// PIPES
import { SquaresPipe } from '../../pipes/squares.pipe';
import { FlagsPipe } from '../../pipes/flags.pipe';
// INTERFACES
import { IDid } from '../../models/dids.model';
import { IPackagePrice } from '../../models/package-price.model';
// COMPONENTS
import { BtnBackComponent } from '../../core/shared/buttons/btn-back/btn-back.component';
import { SpinnerSmallComponent } from '../../core/shared/loading/spinner-small/spinner-small.component';
import { CouponCodeComponent } from '../../core/shared/coupon-code/coupon-code.component';
import { BillingFormComponent } from '../../core/shared/payment/billing-form/billing-form.component';
import { PaymentFormComponent } from '../../core/shared/payment/payment-form/payment-form.component';
// SERRVICES
import { SubmitOrderService } from '../../services/submit-order/submit-order.service';
import { CouponCodeService } from '../../services/coupon-code/coupon-code.service';
import { PackagePriceService } from '../../services/package-price/package-price.service';
import { RecaptchaService } from '../../services/recatpcha/recaptcha.service';
import { GetDIDsService } from '../../services/getDIDs/get-dids.service';
import { AuthService } from 'lib-my-account';
import { LoadingService, PopUpService } from 'lib-shared';
import { GoogleTagManagerService } from '../../services/google-tag-manager/google-tag-manager.service';


@Component({
  selector: 'app-payment-page',
  standalone: true,
  imports: [
    BillingFormComponent,
    BtnBackComponent,
    CouponCodeComponent,
    FlagsPipe,
    PaymentFormComponent,
    RouterModule,
    SquaresPipe,
    SpinnerSmallComponent,
    TranslocoModule,
  ],
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent {
  authService = inject(AuthService);
  couponCodeService = inject(CouponCodeService);
  getDIDsService = inject(GetDIDsService);
  gtmService = inject(GoogleTagManagerService);
  loadingService = inject(LoadingService);
  popUpService = inject(PopUpService);
  packagePriceService = inject(PackagePriceService);
  recaptchaService = inject(RecaptchaService);
  submitOrderService = inject(SubmitOrderService);
  translateService = inject(TranslocoService);

  @ViewChild('paymentForm') paymentForm!: PaymentFormComponent;
  @ViewChild('billingForm') billingForm!: BillingFormComponent;

  paymentId: number = 0;

  couponCode: string = '';
  currentLang: string = '';
  virtualNumberCountryID: number = 0;
  couponPrice: number = 0;
  totalPrice: Signal<number> = signal(0);

  packageCountryName: string = '';
  packageID: number = 0;
  didID: number = 0;

  packagePrice: number = 0;
  didData: IDid | null = null;
  orderData = this.submitOrderService.getOrderData();

  paymentCardForm = this.submitOrderService.submitForm.get(
    'paymentForm'
  ) as FormGroup;
  billingAddressForm = this.submitOrderService.submitForm.get(
    'billingForm'
  ) as FormGroup;
  couponForm = this.submitOrderService.submitForm.get(
    'couponForm'
  ) as FormGroup;
  countriesForm = this.submitOrderService.submitForm.get(
    'countriesForm'
  ) as FormGroup;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.packageCountryName = this.route.snapshot.params['packageCountry'];
    this.packageID = this.route.snapshot.params['packageID'];
    this.didID = this.route.snapshot.params['didID'];
    this.virtualNumberCountryID = this.route.snapshot.params['countryID'];
    this.loadingService.setLoading(true);

    this.getSelectedDID();

    this.getCurrentLang();
  }

  getCurrentLang() {
    // Subscribe to language changes
    this.translateService.langChanges$.subscribe((lang: string) => {
      this.currentLang = lang; // Update currentLang when language changes
    });

    // Initialize currentLang with the current active language
    this.currentLang = this.translateService.getActiveLang();
  }

  ngAfterViewInit(): void {}

  onSelectedSavedCard(cardId: number) {
    this.paymentId = cardId;
  }

  onCouponCodeSubmit(event: string) {
    this.couponCode = event;
    if (this.couponCode !== '') {
      this.validateCouponCode();
    }
  }

  async submitOrder() {
    this.paymentForm.onSubmitPayment();
    this.billingForm.onSubmitBilling();
    const token = await this.recaptchaService.executeRecaptcha(
      'vNumberPurchase'
    );

    if (this.paymentCardForm.valid && this.billingAddressForm.valid) {
      const jsonData = {
        PlatformID: 17,
        CreditCardNumber: this.paymentCardForm.get('cardNumber')?.value,
        CardHolder: this.paymentCardForm.get('cardHolder')?.value,
        SecurityCode: this.paymentCardForm.get('cardCVV')?.value,
        ExpirationMonth: this.paymentCardForm.get('cardExpirationMM')?.value,
        ExpirationYear: this.paymentCardForm.get('cardExpirationYY')?.value,
        BillCountry: this.billingAddressForm.get('country')?.value,
        State: this.billingAddressForm.get('state')?.value,
        City: this.billingAddressForm.get('city')?.value,
        Zip: this.billingAddressForm.get('zipCode')?.value,
        Address: this.billingAddressForm.get('address')?.value,
        Phone: this.orderData.dialCode + this.orderData.phone,
        Email: this.orderData.email,
        PackageID: this.packageID,
        Password: this.orderData.password,
        FirstName: this.orderData.firstName,
        LastName: this.orderData.lastName,
        LanguageISO: this.currentLang,
        IsSaveCard: 1,
        IsPrimary: 1,
        PromoCode: this.couponForm.get('couponCode')?.value,
        PromoCodeParams: {
          PackageID: this.packageID,
          DIDID: this.didData?.didid.toString(),
        },
        DIDID: this.didData?.didid.toString(),
        inquiryTicket: this.orderData.inquiryTicket,
        recaptcha: token,
      };

      this.loadingService.setLoading(true);

      try {
        const res = await this.submitOrderService.submitOrder(jsonData);

        if (res.response_code === 200) {
          this.loadingService.setLoading(false);

          if (this.didData) {
            this.gtmService.purchase(
              this.didData,
              res.confirmationId,
              this.packageID,
              this.packageCountryName,
              this.totalPrice(),
              this.couponCode,
              this.couponPrice
            );
          }
          this.router.navigate(['/order']);
        } else if (res.response_code === 999) {
          this.loadingService.setLoading(false);
          this.popUpService.openPopUp('server-error');
        } else {
          this.loadingService.setLoading(false);
          this.popUpService.openPopUp(res.response_message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async validateCouponCode() {
    const jsonData = {
      PackageID: this.packageID,
      DIDID: this.didData?.didid,
      PromoCode: this.couponForm.get('couponCode')?.value,
      PromoCodeParams: {
        PackageID: this.packageID,
        DIDID: this.didData?.didid,
      },
    };

    try {
      const res = await this.couponCodeService.validateCouponCode(jsonData);

      if (res.response_code === 200) {
        if (res.discount === 0) {
          this.popUpService.openPopUp('code-not-valid');
        } else {
          this.totalPrice = computed(() => {
            return this.packagePrice - res.discount;
          });
        }

        this.couponPrice = res.discount;
      }
    } catch (error) {
      console.error('Coupon validation Error: ', error);
    }
  }

  async getPackagePrice() {
    try {
      const jsonData = {
        packageId: Number(this.packageID),
        countryId: this.virtualNumberCountryID,
        didId: this.didID,
        price: 0,
      };
      const res = await this.packagePriceService.getPackagePrice<IPackagePrice>(
        jsonData
      );

      if (res.isSuccessful && res.result) {
        this.packagePrice = res.result.price;

        setTimeout(() => {
          this.totalPrice = computed(() => {
            return this.packagePrice;
          });
        }, 1000);

        if (this.didData) {
          this.gtmService.addPaymentInfo(
            this.didData,
            this.packagePrice,
            this.packageID,
            this.packageCountryName,
            this.couponCode,
            this.couponPrice
          );
        }
      }
    } catch (error) {
      console.error(error);
      this.popUpService.openPopUp('server-error');
    }
  }

  async getSelectedDID() {
    // const didID = this.route.snapshot.params['didID'];
    const res = await this.getDIDsService.getSelectedDID<IDid>(this.didID);

    if (res.isSuccessful) {
      this.didData = res.result;
      this.getPackagePrice();
      this.loadingService.setLoading(false);
    }
  }

  onRemoveCouponCode() {
    this.couponCode = '';
    this.couponPrice = 0;

    this.totalPrice = computed(() => {
      return this.packagePrice;
    });
  }
}
