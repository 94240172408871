<article #searchCountries class="bg-white rounded-3xl shadow-full p-10 animate-fade-right z-[9997]">
  <h3 class="font-bold py-4" transloco="search-country.title-vnumber-country"></h3>
  <!-- <app-search-input type="vnum"></app-search-input> -->
  <app-search-input [placeholder]="'search-country.input-placeholder'" type="vnum"
    (selectedCountry)="onVirtualNumberCountrySelected($event)"></app-search-input>
  <h3 class="font-bold py-4" transloco="search-country.title-your-country"></h3>
  <!-- <app-search-input type="place"></app-search-input> -->
  <app-search-input [placeholder]="'search-country.input-placeholder'" type="place"
    (selectedCountry)="onPackageCountrySelected($event)"></app-search-input>

  @if (countriesForm.valid) {
  <div class="flex justify-center pt-5">
    <app-btn-primary text="get-your-number" [link]="getLink()">
    </app-btn-primary>
  </div>
  }

</article>