import { Injectable } from '@angular/core';
declare var clarity: any;

@Injectable({
  providedIn: 'root',
})
export class ClarityService {
  async SetTag(tag: string, value: any) {
    try {
      clarity('set', tag, value);
      
    } catch (error) {
      console.log(error)
    }
  }
}
