<div id="faq" class="relative w-full bg-white pb-8 sm:rounded-lg">
  <div *ngFor="let item of faqItems | slice:0:(faqSrc === 'Home' ? 7 : 15)"
    class="grid border-b">
    <div class="py-8">
      <details class="group">
        <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
          <span [innerHTML]="item.q | transloco" class="transition group-open:text-orange font-semibold"></span>
          <span class="transition group-open:rotate-180 sm:ml-4">
            <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor"
              stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <p class="group-open:animate-fadeIn mt-3 text-neutral-600" [innerHTML]="item.a | transloco">
        </p>
      </details>
    </div>
  </div>

  <div
    class="border-b border-greyLight hidden">
    <h2 class="mb-0" id="headingTwo">
      <button
        class="group relative flex w-full items-center bg-white px-5 py-4 text-left text-sm transition [&:not([data-te-collapse-collapsed])]:text-orange"
        type="button"
        data-te-collapse-init
        data-te-collapse-collapsed
        data-te-target="#collapseTwo"
        aria-expanded="false"
        aria-controls="collapseTwo">
        <strong>Accordion de coso </strong>
        <span
          class="-mr-1 ml-auto h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </span>
      </button>
    </h2>
    <div
      id="collapseTwo"
      class="!visible hidden"
      data-te-collapse-item
      aria-labelledby="headingTwo"
      data-te-parent="#faq">
      <div class="px-5 py-4">
        colapsado de coso
      </div>
    </div>
  </div>

</div>