import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideTranslocoWithConfig, SettingsService } from 'lib-shared';
import { provideMyAccount, AuthInterceptor } from 'lib-my-account';
import { ControllerApiList } from './models/controllers/ControllerList';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat'
import { provideTranslocoLocale } from '@jsverse/transloco-locale'
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    { 
      provide: 'APP_ENVIRONMENT', 
      useValue: environment 
    },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withViewTransitions()),
    provideClientHydration(withHttpTransferCacheOptions({includePostRequests: true})),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideTranslocoWithConfig(),
    provideTranslocoMessageformat(),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        es: 'es-ES',
        he: 'he-IL',
        pt: 'pt-BR',
      },
    }),
    // provideTranslocoPersistLang({
    //   storage: {
    //     useValue: localStorage,
    //   },
    // }),
    {
      provide: APP_INITIALIZER,
      useFactory: (settings: SettingsService) => {
        return () => settings.updateSettings({ appName: 'vnumber' });
      },
      deps: [SettingsService],
      multi: true
    },
    provideMyAccount({
      apiBaseUrl: '',
      sidebarConfig: {
        availableCreditEnabled: true,
        items: [
          {
            path: '/profile/my-vnumbers',
            icon: '/images/icons/profile/sim.svg',
            iconActive: '/images/icons/profile/sim-w.svg',
            labelKey: 'profile-vn.sidebar.my-vnumbers'
          },
          {
            path: '/profile/account-information',
            icon: '/images/icons/profile/user.svg',
            iconActive: '/images/icons/profile/user-w.svg',
            labelKey: 'profile-vn.sidebar.account-info'
          },
          {
            path: '/profile/billing',
            icon: '/images/icons/profile/card.svg',
            iconActive: '/images/icons/profile/card-w.svg',
            labelKey: 'profile-vn.sidebar.billing'
          },
          {
            path: '/profile/support',
            icon: '/images/icons/profile/help.svg',
            iconActive: '/images/icons/profile/help-w.svg',
            labelKey: 'profile-vn.sidebar.support'
          },
          // {
          //   path: '/profile/free-credit',
          //   icon: '/images/icons/profile/dollar.svg',
          //   iconActive: '/images/icons/profile/dollar-w.svg',
          //   labelKey: 'profile-vn.sidebar.free-credit'
          // }
        ]
      },
      controllerApiList: {
        HubApi: {
          SignIn: `${ControllerApiList.HubApi.VnumberApi.SignIn}`,
          ResetPassword: `${ControllerApiList.HubApi.VnumberApi.ResetPassword}`,
          ValidateCustomerPassword: `${ControllerApiList.HubApi.VnumberApi.ValidateCustomerPassword}`,
          GetAccountInfo: `${ControllerApiList.HubApi.VnumberApi.GetAccountInfo}`,
          VerifyEmailChange: `${ControllerApiList.HubApi.VnumberApi.VerifyEmailChange}`,
          ValidateCustomerEmail: `${ControllerApiList.HubApi.VnumberApi.ValidateCustomerEmail}`,
          Inquiry: `${ControllerApiList.HubApi.Inquiry}`
        }
      },
      title: "profile-vn.title.",
      logOut: 'btn-vn.log-out',
      theme: {
        primaryColor: '#FF5733',
        secondaryColor: '#4A90E2',
        logo: '/images/logo.webp'
      }
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
};
