import { Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { TranslocoModule } from '@jsverse/transloco';
// INTERFACES
import { IDid, IDids } from '../../models/dids.model';
import { IPackagePrice } from '../../models/package-price.model';
// COMPONENTS
import { SpinnerSmallComponent } from '../../core/shared/loading/spinner-small/spinner-small.component';
import { NeedMoreInfoFormComponent } from '../../core/shared/choose-your-number/need-more-info-form/need-more-info-form.component';
// SERVICES
import { GetDIDsService } from '../../services/getDIDs/get-dids.service';
import { SubmitOrderService } from '../../services/submit-order/submit-order.service';
import { PackagePriceService } from '../../services/package-price/package-price.service';
import { GoogleTagManagerService } from '../../services/google-tag-manager/google-tag-manager.service';
// PIPES
import { SlidersPipe } from '../../pipes/sliders.pipe';
import { FlagsPipe } from '../../pipes/flags.pipe';

@Component({
  selector: 'app-choose-number-page',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NeedMoreInfoFormComponent,
    TranslocoModule,
    SlidersPipe,
    FlagsPipe,
    SpinnerSmallComponent,
  ],
  templateUrl: './choose-number-page.component.html',
  styleUrl: './choose-number-page.component.scss',
})
export class ChooseNumberPageComponent {
  getDIDsService = inject(GetDIDsService);
  gtmService = inject(GoogleTagManagerService);
  packagePriceService = inject(PackagePriceService);
  submitOrderService = inject(SubmitOrderService);

  vNumberCountryName: string = "";
  packageCountryName: string = "";
  packageCountryIso: string = "";
  virtualNumberCountryID: number = 0;
  packageID: string = "";
  virtualNumbers: IDids[] = [];

  showNumbers: boolean = false;

  packagePrice: IPackagePrice | null = null;
  priceInteger: string | null = null;
  priceDecimal: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
  ) {}

  ngOnInit(): void {
    this.vNumberCountryName = this.route.snapshot.params['vNumCountry'];
    this.packageCountryName = this.route.snapshot.params['packageCountry'];
    this.packageCountryIso = this.route.snapshot.params['packageIso'];
    this.virtualNumberCountryID = this.route.snapshot.params['countryID'];
    this.packageID = this.route.snapshot.params['packageID'];

    this.getAvailableDIDs(this.virtualNumberCountryID);
    this.getPackagePrice();

    this.updateMetaTags(
      '#VNumber | Choose Your Number',
      'virtual number, vnumber, connectivity ',
      'Expand horizons, stay local'
    );
  }

  updateMetaTags(title: string, keywords: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      rel: 'canonical',
      href: 'https://vnumber.com/choose-your-number',
    });
  }

  isFirstIsrael(index: number): boolean {
    return this.virtualNumbers.findIndex(vn => vn.state === 'Israel') === index;
  }
  onChooseVNumber(didData: IDid) {
    this.submitOrderService.setOrderData({ didData: didData });
    this.gtmService.addToCart(didData, this.packagePrice?.price, this.packageID, this.packageCountryName)
    this.router.navigate([
      'personal-information',
      this.virtualNumberCountryID,
      this.packageCountryName,
      this.packageCountryIso,
      this.packageID,
      didData.didid
    ]);
  }

  checkRates() {
    this.router.navigate(['rates-per-country', this.packageID]);
  }

  async getAvailableDIDs(countryID: number) {
    try {
      const res = await this.getDIDsService.getAvailableDIDs<IDids[]>(countryID);
      this.virtualNumbers = res.result;
      const allDids = this.virtualNumbers.flatMap(item => item.dids);
      this.gtmService.viewItem(allDids, this.packageID, this.packageCountryName)
    } catch (error) {}
  }

  async getPackagePrice() {
    try {
      const jsonData = {
        packageId: Number(this.packageID),
        countryId: this.virtualNumberCountryID,
        didId: null,
        price: 0,
      };
      const res = await this.packagePriceService.getPackagePrice<IPackagePrice>(jsonData);

      if (res.isSuccessful && res.result) {
        this.packagePrice = res.result
        const priceParts = this.packagePrice.price.toFixed(2).split('.');
        this.priceInteger = priceParts[0];
        this.priceDecimal = priceParts[1];
      }
    } catch (error) {
      console.error(error);
    }
  }
}
