import { ChangeDetectorRef, Component, ElementRef, inject, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { FlagsPipe } from '../../../../../pipes/flags.pipe';
import { PhoneFormatPipe } from '../../../../../pipes/phone-format.pipe';
import { CallerIdService } from '../../../../../services/caller-id/caller-id.service';
import { PopUpService } from 'lib-shared';
import { ICallerID, ICallerIDResult } from '../../../../../models/caller-id.model';

@Component({
  selector: 'app-my-cli',
  standalone: true,
  imports: [FlagsPipe, FormsModule, PhoneFormatPipe, ReactiveFormsModule, TranslocoModule],
  templateUrl: './my-cli.component.html',
  styleUrl: './my-cli.component.scss',
})
export class MyCliComponent {
  @ViewChild('nameInput') nameInput!: ElementRef;
  @ViewChild('prefixInput') prefixInput!: ElementRef;
  @ViewChild('prefix1Input') prefix1Input!: ElementRef;

  callerIdService = inject(CallerIdService);
  popUpService = inject(PopUpService);

  numbers: ICallerID[] = [];

  isAddNewPhone: boolean = false;
  formSubmitted: boolean = false;

  initialPhoneValue: string = '';

  deleteConfirmationId: number | null = null;
  maxCallerIDsAllowed: number = 0;
  editingId: number | null = null;

  newCLIForm: FormGroup;
  editCLIForm: FormGroup;

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.newCLIForm = this.fb.group({
      newPrefix: ['', Validators.required],
      newPhone: ['', Validators.required],
      confirmNewPrefix: ['', Validators.required],
      confirmNewPhone: ['', Validators.required],
      newName: ['']
    }, { validators: this.phoneMatchNewValidator() })
    this.editCLIForm = this.fb.group({
      prefix: ['', [Validators.required, Validators.maxLength(3),Validators.pattern('^[0-9]*$')]],
      phone: ['', [Validators.required, Validators.maxLength(15), Validators.pattern('^[0-9]*$')]],
      confirmPrefix: [''],
      confirmPhone: [''],
      name: [''],
      id: [0]
    }, { validators: this.phoneMatchEditValidator() })
  }

  ngOnInit(): void {
    this.getCallerIdNumber();

    this.editCLIForm.get('prefix')?.valueChanges.subscribe(() => {
      this.toggleConfirmValidators();
    });
    this.editCLIForm.get('phone')?.valueChanges.subscribe(() => {
      this.toggleConfirmValidators();
    });
  }

  phoneMatchEditValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const prefix = form.get('prefix')?.value;
      const phone = form.get('phone')?.value;
      const confirmPrefix = form.get('confirmPrefix')?.value;
      const confirmPhone = form.get('confirmPhone')?.value;
      // Only validate if all fields have values
      if (prefix && phone && confirmPrefix && confirmPhone) {
        return prefix === confirmPrefix && phone === confirmPhone ? null : { phoneMismatch: true };
      }
      return null;
      // return newPhone === confirmPhone ? null : { phoneMismatch: true };
    };
  }

  phoneMatchNewValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const newPhone = form.get('newPrefix')?.value + form.get('newPhone')?.value;
      const confirmPhone = form.get('confirmNewPrefix')?.value + form.get('confirmNewPhone')?.value;;
      return newPhone === confirmPhone ? null : { phoneMismatch: true };
    };
  }

  toggleConfirmValidators() {
    const prefixChanged = this.editCLIForm.get('prefix')?.dirty;
    const phoneChanged = this.editCLIForm.get('phone')?.dirty;
    if (prefixChanged || phoneChanged) {
      this.editCLIForm.get('confirmPrefix')?.setValidators([
        Validators.required,
        Validators.maxLength(3),  // Asumiendo un máximo de 3 dígitos para el prefijo
        Validators.pattern('^[0-9]*$')
      ]);
      this.editCLIForm.get('confirmPhone')?.setValidators([
        Validators.required,
        Validators.maxLength(15),  // Máximo de 15 dígitos según E.164
        Validators.pattern('^[0-9]*$')
      ]);
    } else {
      this.editCLIForm.get('confirmPrefix')?.clearValidators();
      this.editCLIForm.get('confirmPhone')?.clearValidators();
    }
    this.editCLIForm.get('confirmPrefix')?.updateValueAndValidity();
    this.editCLIForm.get('confirmPhone')?.updateValueAndValidity();
  }

  onInputChange(event: Event, controlName: string) {
    const input = event.target as HTMLInputElement;
    const sanitizedValue = input.value.replace(/[^0-9]/g, '');
    this.newCLIForm.get(controlName)?.setValue(sanitizedValue, {emitEvent: false});
  }

  onKeyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  // Only allow adding if the maximum has not been reached.
  canAddMorePhones(): boolean {
    return this.numbers.length < this.maxCallerIDsAllowed;
  }

  // Only allow deleting if there is more than 1 number
  canDeletePhone(): boolean {
    return this.numbers.length > 1;
  }

  confirmDelete(id: number) {
    this.deleteConfirmationId = id
  }

  addNewPhone() {
    if (this.canAddMorePhones()) {
      this.isAddNewPhone = true;
      this.cdr.detectChanges(); // Ensures that the view has been updated
      this.prefix1Input?.nativeElement.focus();
    }
  }

  cancelAddNewPhone() {
    this.isAddNewPhone = false;
    this.newCLIForm.reset()
  }

  startEditing(id: number) {

    this.editingId = id;
    const numberToEdit = this.numbers.find(num => num.id === id);

    if (numberToEdit) {
      // Updates the form with all values
      this.editCLIForm.patchValue({
        prefix: numberToEdit.prefix,
        phone: numberToEdit.callerId.replace(numberToEdit.prefix, ''),
        name: numberToEdit.comment,
        id: numberToEdit.id
      });
    }

    this.editCLIForm.patchValue({
      id: id
    });

    if(numberToEdit) {
      this.initialPhoneValue = numberToEdit.prefix + numberToEdit.callerId.replace(numberToEdit.prefix, '');
    }
  }
  stopEditing() {
    this.editingId = null;
    const phone = this.editCLIForm.get('phone')
    const prefix = this.editCLIForm.get('prefix')
    const confirmPhone = this.editCLIForm.get('confirmPhone')
    const confirmPrefix = this.editCLIForm.get('confirmPrefix')

    phone?.markAsPristine()
    prefix?.markAsPristine()
    confirmPhone?.clearValidators();
    confirmPrefix?.clearValidators();

    confirmPrefix?.reset();
    confirmPhone?.reset();
  }

  async onSubmitNewPhone() {
    const { newPrefix, newPhone, newName } = this.newCLIForm.value
    this.formSubmitted = true

    if (this.newCLIForm.valid) {

      const jsonData = {
        newCallerId: newPrefix + newPhone,
        comment: newName
      }

      try {
        const res = await this.callerIdService.addCLINumber(jsonData)

        if (res.isSuccessful) {
          if (res.result) {
            this.getCallerIdNumber();
            this.newCLIForm.reset()
            this.formSubmitted = false;
          } else {
            this.popUpService.openPopUp('server-error');
          }
        }
      } catch (error) {
        console.error(error)
        this.popUpService.openPopUp('server-error');
      } finally {
        this.cancelAddNewPhone()
        this.formSubmitted = false;
      }
    }
  }

  async getCallerIdNumber() {
    try {
      const res = await this.callerIdService.getCallerIds<ICallerIDResult>();

      if (res.isSuccessful && res.result) {
        this.numbers = res.result.callerIds
        this.maxCallerIDsAllowed = res.result.maxCallerIDsAllowed;
      }
    } catch (error) {
      console.error(error)
    }
  }

  async updateCallerId() {
    this.formSubmitted = true
    let jsonData;
    if (this.editCLIForm.valid) {
      const { prefix, phone, name, id } = this.editCLIForm.value

      const currentPhoneValue = prefix + phone;

      if (currentPhoneValue !== this.initialPhoneValue) {
        jsonData = {
          id: id,
          newCallerId: currentPhoneValue,
          comment: name
        }
      } else {
        jsonData = {
          id: id,
          comment: name
        }
      }

      try {
        const res = await this.callerIdService.updateCLINumber(jsonData)

        if (res.isSuccessful) {
          if (res.result) {
            this.getCallerIdNumber();
            this.stopEditing();
            this.formSubmitted = false;
          }else {
            this.popUpService.openPopUp('server-error');
          }
        }
      } catch (error) {
        console.error(error)
        this.popUpService.openPopUp('server-error');
      }
    }
  }
  async deleteCallerId(id: number) {
    if (this.canDeletePhone()) {
      try {
        const res = await this.callerIdService.deleteCLINumber(id);
        if (res.isSuccessful) {
          this.getCallerIdNumber();
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
}
