<main id="RetireesPage" class="py-10">
  <section class="flex flex-col items-center px-5 mlg:flex-row justify-center gap-16 pb-10">
    <div class="w-full lg:w-[400px] smd:py-10">
      <article class="animate-fade-right px-6">
        <h2 class="py-5 leading-none" [innerHTML]="'retirees.title' | transloco">Re</h2>
      </article>

      <article class="bg-white rounded-3xl shadow-full p-10 animate-fade-right z-[9997]">
        <h3 class="text-3xl font-normal py-4" [innerHTML]="'retirees.where-you-are' | transloco"></h3>
        <div class="mb-4 w-full sm:w-[320px] mr-auto">
          <app-search-input [placeholder]="'search-country.input-placeholder'" type="place"
            (selectedCountry)="onCountrySelected($event)"></app-search-input>
        </div>

        @if (packageCountry) {
          <div class="flex justify-center pt-5">
            <app-btn-primary text="get-it-now" [link]="getLink()">
            </app-btn-primary>
          </div>
          }
      </article>
    </div>

    <div class="relative">
      <img src="/images/icons/retirees/main-retirees.webp" alt="" class="w-full">

      <div
        class="bg-white absolute right-20 smd:right-40 -bottom-10 flex flex-col items-center w-fit h-fit rounded-xl shadow-xl p-4 text-center ">
        <div class="relative flex items-end space-x-2 w-[190px]" style="direction: ltr;">
          <!-- <div class="text-grey strikethrough text-2xl my-auto">$5<span class="text-base align-top">99</span></div> -->
          <div class="font-medium text-[4rem] leading-none ">
            <span class="text-4xl align-middle">$</span>5<span class="text-4xl align-top">99</span>
          </div>
          <div class="absolute left-[56px] top-[30px] text-grey " transloco="choose-number.price"></div>
        </div>

        <span class="text-start text-lg" [innerHTML]="'retirees.keep-us-number' | transloco"></span>
      </div>
    </div>

  </section>

  <!-- ADVANTAGES -->
  <section class="px-6 smd:px-20 mt-12">
    <h2 class="text-center py-10 font-semibold" [innerHTML]="'retirees.advantages.title' | transloco"></h2>

    <div class="flex smd:hidden">
      <app-card-carousel></app-card-carousel>
    </div>

    <div class="hidden lg:flex relative w-full overflow-hidden py-4 mb-12">
      <button
        class="wide:hidden absolute left-4 top-1/2 transform -translate-y-1/2 text-4xl font-bold bg-white rounded-full shadow-full p-2 z-10 "
        (click)="prevSlide()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="orange"
          class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
      </button>

      <!-- Contenedor del carrusel -->
      <div class="flex transition-transform duration-1000 ease-in-out gap-8 mx-12"
        [style]="{'transform': 'translateX(' + (-currentIndex * (100 / visibleItems)) + '%)'}">

        @for (item of items; track $index) {
        <div class="flex flex-col items-center text-center">
          <article class="relative shadow-full rounded-3xl p-6 w-[300px] h-[250px] bg-white">
            <img [src]="'/images/icons/' + item.position + 'vnumber.svg'" alt="" class="w-16 absolute left-0 top-5">
            <h3 class="pt-8" [transloco]="'home.advantages.' + item.title"></h3>
            <p class="text-grey" [transloco]="'home.advantages.' + item.description"></p>
          </article>
        </div>
        }
      </div>

      <!-- Botones de navegación -->

      <button
        class="wide:hidden absolute right-4 top-1/2 transform -translate-y-1/2 text-4xl font-bold bg-white rounded-full shadow-full p-2 z-10 "
        (click)="nextSlide()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="orange"
          class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
        </svg>

      </button>
    </div>

  </section>

  <section class="flex flex-col items-center px-5">
    <article class="bg-white flex flex-col lg:flex-row gap-6 rounded-2xl shadow-full p-6 my-12">
      <div class="max-w-60">
        <h3 class="text-4xl mb-6">
          <span transloco="retirees.connect-your-number-title">Connect your virtual number right now</span>
        </h3>
        <span class="text-sm font-normal" transloco="retirees.connect-your-number-subtitle">we have an ideal promo for you</span>
      </div>

      <div class="flex flex-col md:flex-row gap-6">
        <img src="/images/icons/retirees/square-1.webp" alt="">
        <img src="/images/icons/retirees/square-2.webp" alt="">
      </div>
    </article>
  </section>

  <!-- FAQ -->
  <section class="flex flex-col items-center px-5">
    <h2 class="text-center py-10 font-semibold" transloco="home.faq-title"></h2>
    <div class="relative border border-greyDark rounded-3xl p-6 shadow-full mt-6 lg:w-[946px]">
      <img src="/images/icons/FAQ_3vnumber.svg" alt="" class="w-36 absolute left-20 -top-[52px] z-20 ">
      <app-faq-accordion faqName="faq-vn.retirees." faqSrc="Home"></app-faq-accordion>
    </div>
  </section>

  <section class="py-16 px-5">
    <h2 class="text-center pb-16 font-semibold" transloco="choose-number.need-more-info.title"></h2>
    <app-need-more-info-form></app-need-more-info-form>
  </section>

</main>