import { CommonModule } from '@angular/common';
import { Component, NgZone } from '@angular/core';

@Component({
  selector: 'app-image-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-carousel.component.html',
  styleUrl: './image-carousel.component.scss',
})
export class ImageCarouselComponent {
  images: string[] = [
    '/images/icons/dialogue/1.svg',
    '/images/icons/dialogue/2.svg',
    '/images/icons/dialogue/3.svg',
    '/images/icons/dialogue/4.svg',
    '/images/icons/dialogue/5.svg',
    '/images/icons/dialogue/6.svg',
    '/images/icons/dialogue/7.svg',
    // '/images/icons/dialogue/8.svg',
  ];
  currentIndex: number = 0;
  private intervalId: any;

  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.startCarousel();
    }, 100);
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  startCarousel(): void {
    let me = this;
    this.ngZone.runOutsideAngular(() => {
      me.intervalId = setInterval(() => {
        me.currentIndex = (me.currentIndex + 1) % me.images.length;
      }, 3000);
    });
  }
}
