<main class="relative px-5 pb-16">

  <section class="flex flex-col items-center">
    <div class="flex flex-col w-fit py-12">
      <img src="/images/icons/personalInfo/shape.svg" alt="" class="w-20">
      <h2 class="text-center px-12 py-4 font-semibold" [innerHTML]="'calling-rates.title' | transloco"></h2>
      <img src="/images/icons/personalInfo/shape2.svg" alt="" class="w-32 ml-auto">
    </div>
    <span class="text-center px-12 py-4 max-w-[520px]" [innerHTML]="'calling-rates.subtitle' | transloco"></span>
  </section>

  <section>
    <div class="flex flex-col items-center py-16 max-w-3xl mx-auto">
      <div class="mb-4 w-full sm:w-[320px] mr-auto">
        <app-search-input [placeholder]="'search-country.input-placeholder'" type="rate"
          (selectedCountry)="onCountrySelected($event)"></app-search-input>
      </div>
      <div class="overflow-hidden rounded-lg border w-full">
        <table class="w-full">
          <thead class="">
            <tr class="bg-orange text-white text-center divide-x">
              <th class="px-2 py-4" transloco="calling-rates.table-head.country"></th>
              <th class="px-2 py-4" transloco="calling-rates.table-head.destination"></th>
              <th class="px-2 py-4" transloco="calling-rates.table-head.price"></th>
            </tr>
          </thead>
          <tbody class="">
            @for (country of countries; track $index) {
            <tr class="text-center divide-x divide-y">
              <td class="p-2 border-y">{{ country.country }}</td>
              <td class="p-2 capitalize">{{ country.destination }}</td>
              <td class="p-2">${{ country.rate }}</td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </section>
</main>