import {
  Component,
  ElementRef,
  HostListener,
  inject,
  ViewChild,
} from '@angular/core';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { ForwardingSettingsComponent } from '../forwarding-settings/forwarding-settings.component';
import { CallHistoryComponent } from '../call-history/call-history.component';
import { MyCliComponent } from '../my-cli/my-cli.component';
import { FlagsPipe } from '../../../../../pipes/flags.pipe';
import { PhoneFormatPipe } from '../../../../../pipes/phone-format.pipe';
import { IMyVNumbers } from '../../../../../models/my-vnumbers.model';
import { MyVnumbersService } from '../../../../../services/my-vnumbers/my-vnumbers.service';

@Component({
  selector: 'app-my-vnumbers',
  standalone: true,
  imports: [
    CallHistoryComponent,
    FlagsPipe,
    ForwardingSettingsComponent,
    MyCliComponent,
    PhoneFormatPipe,
    TranslocoModule,
  ],
  templateUrl: './my-vnumbers.component.html',
  styleUrl: './my-vnumbers.component.scss',
})
export class MyVnumbersComponent {
  @ViewChild('popover') popoverElement!: ElementRef;
  openPopoverIndex: number | null = null;
  translocoService = inject(TranslocoService);
  numbers: IMyVNumbers[] = [];
  selectedNumber: IMyVNumbers | null = null;

  constructor(private el: ElementRef, private myVnumberService: MyVnumbersService) {}

  ngOnInit(): void {
    this.getMyVNumbers();
  }

  toggleDetails(number: IMyVNumbers): void {
    this.selectedNumber = this.selectedNumber === number ? null : number;
  }

  onUpdateForwarding(number: IMyVNumbers) {
    this.getMyVNumbers();
    this.selectedNumber = null
    this.toggleDetails(number)
  }

  async getMyVNumbers() {
    try {
      const res = await this.myVnumberService.getMyVNumbers<IMyVNumbers[]>()

      if (res.isSuccessful) {
        this.numbers = res.result
      }
    } catch (error) {
      console.error(error)
    }
  }
}
