import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-btn-toggle-password-visibility',
  standalone: true,
  imports: [],
  templateUrl: './btn-toggle-password-visibility.component.html',
  styleUrl: './btn-toggle-password-visibility.component.scss'
})
export class BtnTogglePasswordVisibilityComponent {
 @Input() showPassword = false;
 @Input() top: string = '11'
 @Output() togglePswVisibility: EventEmitter<void> = new EventEmitter<void>();

 toggleVisibility() {
  this.showPassword = !this.showPassword
  this.togglePswVisibility.emit()
 }
}
