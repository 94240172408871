import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { ControllerApiList } from '../../models/controllers/ControllerList';
import { ISubmitOrder } from '../../models/submit-order';
import { SettingsService } from '../../../../../lib-shared/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class SubmitOrderService {
  private orderData: any = {};
  submitForm: FormGroup;

  constructor(public formBuilder: FormBuilder, private http:HttpClient, private settings: SettingsService) {
    this.submitForm = this.formBuilder.group({
      personalInfoForm: this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', Validators.required],
        dialCode: [''],
        password: ['', [Validators.required, Validators.minLength(8)]],
      }),
      billingForm: this.formBuilder.group({
        address: ['', Validators.compose([Validators.required, Validators.minLength(5)]), ],
        country: ['', Validators.required],
        state: ['', Validators.required],
        city: ['', Validators.required],
        zipCode: ['', Validators.required],
      }),
      paymentForm: this.formBuilder.group({
        cardHolder: ['', Validators.compose([Validators.required, Validators.minLength(3)]), ],
        cardNumber: ['', Validators.compose([
            Validators.required,
            Validators.minLength(13),
            Validators.maxLength(16),
        ]),],
        cardExpirationMM: ['', Validators.compose([Validators.required])],
        cardExpirationYY: ['', Validators.compose([Validators.required])],
        cardCVV: ['', Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(4),
        ]),],
      }),
      couponForm: this.formBuilder.group({
        couponCode: ['', Validators.required],
      }),
      countriesForm: this.formBuilder.group({
        virtualNumberCountryName: ['', Validators.required],
        virtualNumberCountryId: ['', Validators.required],
        packageCountryName: ['', Validators.required],
        packageCountryId: ['', Validators.required]
      })
    }); 
  }
  

  
  async submitOrder(order: any) {    
    const res = this.http.post<ISubmitOrder>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.SubmitOrder}`, order)
    const data = await lastValueFrom(res)
    
    return data
  }
  
  setOrderData(data: any) {
    this.orderData = { ...this.orderData, ...data };
  }
  
  getOrderData(): any {
    return { ...this.orderData };
  }
  
  clearOrderData() {
    this.orderData = {};
  }
}